<template>
    <!--
    Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
    Read the documentation to get started: https://tailwindui.com/documentation
  -->
    <div class="h-full flex overflow-hidden bg-dark-base py-3">
        <!-- Static sidebar for desktop -->
        <div class="flex">
            <div class="flex flex-col border-r border-dark-light bg-dark-base overflow-y-auto">
                <!-- Sidebar component, swap this element with another sidebar if you like -->
                <div class="flex flex-col flex-1">
                    <!-- Navigation -->
                    <nav class="space-y-1">
                        <div
                            class="flex items-center h-18 cursor-pointer transition-all duration-500 hover:bg-dark-lighter"
                        >
                            <router-link :to="{ name: 'sessions.index' }" class="flex-1 text-center m-2">
                                <div
                                    class="p-2 text-xs flex flex-col items-center font-light"
                                    :class="{ 'bg-dark-lighter rounded-lg': currentRouteLayout === 'sessions' }"
                                >
                                    <ListBulletIcon class="w-6 h-6" />
                                    <div>{{ $t('session.title', 2) }}</div>
                                </div>
                            </router-link>
                        </div>

                        <div
                            v-if="$store.state.auth?.user?.profile === 1"
                            class="flex items-center h-18 cursor-pointer transition-all duration-500 hover:bg-dark-lighter"
                        >
                            <router-link :to="{ name: 'comparison.index' }" class="flex-1 text-center m-2">
                                <div
                                    class="p-2 text-xs flex flex-col items-center font-light"
                                    :class="{ 'bg-dark-lighter rounded-lg': currentRouteLayout === 'comparison' }"
                                >
                                    <DocumentDuplicateIcon class="w-6" />
                                    <div>{{ $t('comparison.title') }}</div>
                                </div>
                            </router-link>
                        </div>

                        <div
                            class="flex items-center h-18 cursor-pointer transition-all duration-500 hover:bg-dark-lighter"
                        >
                            <router-link :to="{ name: 'horses.index' }" class="flex-1 text-center m-2">
                                <div
                                    class="p-2 text-xs flex flex-col items-center font-light"
                                    :class="{ 'bg-dark-lighter rounded-lg': currentRouteLayout === 'horses' }"
                                >
                                    <DressageIcon class="w-8 -ml-1" />
                                    <div>{{ $t('horse.title', 2) }}</div>
                                </div>
                            </router-link>
                        </div>

                        <div
                            class="flex items-center h-18 cursor-pointer transition-all duration-500 hover:bg-dark-lighter"
                        >
                            <router-link
                                :to="{ name: 'teams.sessions.index', meta: { layout: 'teams' } }"
                                class="flex-1 text-center m-2"
                            >
                                <div
                                    class="p-2 text-xs flex flex-col items-center font-light"
                                    :class="{ 'bg-dark-lighter rounded-lg': currentRouteLayout === 'teams' }"
                                >
                                    <UsersIcon class="w-6 h-6" />
                                    <div>{{ $t('team.title', 2) }}</div>
                                </div>
                            </router-link>
                        </div>

                        <div
                            class="flex items-center h-18 cursor-pointer transition-all duration-500 hover:bg-dark-lighter"
                        >
                            <router-link :to="{ name: 'sensors.index' }" class="flex-1 text-center m-2">
                                <div
                                    class="p-2 text-xs flex flex-col items-center font-light"
                                    :class="{ 'bg-dark-lighter rounded-lg': currentRouteLayout === 'sync' }"
                                >
                                    <CloudArrowUpIcon class="w-6 h-6" />
                                    <div>{{ $t('sensor.sync', 2) }}</div>
                                </div>
                            </router-link>
                        </div>

                        <div
                            class="flex items-center h-18 cursor-pointer transition-all duration-500 hover:bg-dark-lighter"
                        >
                            <router-link :to="{ name: 'protocols.index' }" class="flex-1 text-center m-2">
                                <div
                                    class="p-2 text-xs flex flex-col items-center font-light"
                                    :class="{ 'bg-dark-lighter rounded-lg': currentRouteLayout === 'protocols' }"
                                >
                                    <NewspaperIcon class="w-6 h-6" />
                                    <div>{{ $t('protocol.title', 2) }}</div>
                                </div>
                            </router-link>
                        </div>

                        <div
                            v-if="$store.state.auth?.user?.job_name === 'coach'"
                            class="flex items-center h-18 cursor-pointer transition-all duration-500 hover:bg-dark-lighter"
                        >
                            <router-link :to="{ name: 'coaching.sessions.index' }" class="flex-1 text-center m-2">
                                <div
                                    class="p-2 text-xs flex flex-col items-center font-light"
                                    :class="{ 'bg-dark-lighter rounded-lg': currentRouteLayout === 'coaching' }"
                                >
                                    <PresentationChartLineIcon class="w-6 h-6" />
                                    <div>{{ $t('coaching.title') }}</div>
                                </div>
                            </router-link>
                        </div>
                    </nav>
                </div>
                <!-- <div class="relative text-center"> -->
                <!-- Dropdown menu toggle, controlling the show/hide state of dropdown menu. -->
                <div class="flex items-center h-18 cursor-pointer transition-all duration-500 hover:bg-dark-lighter">
                    <router-link :to="{ name: 'settings.profile' }" class="flex-1 text-center m-4">
                        <div class="p-2" :class="{ 'bg-dark-lighter rounded-lg': currentRouteLayout === 'settings' }">
                            <div class="flex justify-center items-center">
                                <div class="flex items-center justify-center">
                                    <img
                                        class="inline-block h-8 w-8 rounded-full"
                                        :src="$store.state.auth.user?.avatar"
                                    />
                                </div>
                            </div>
                        </div>
                    </router-link>
                    <!-- </div> -->
                </div>
            </div>
        </div>

        <div
            v-show="showSecondColumn"
            class="flex flex-col flex-1 lg:flex-initial lg:w-1/3 border-r border-dark-light bg-dark-base overflow-y-auto"
        >
            <div class="px-4">
                <router-view name="secondColumn" />
            </div>
        </div>
        <!-- Main column -->

        <main
            v-show="showThirdColumn"
            class="flex-1 relative z-0 h-full overflow-x-hidden focus:outline-none overflow-y-auto px-8"
            tabindex="0"
        >
            <router-view name="thirdColumn" />
        </main>
    </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import tailwind from '@/lib/tailwind'

import {
    ListBulletIcon,
    DocumentDuplicateIcon,
    UsersIcon,
    CloudArrowUpIcon,
    NewspaperIcon,
    PresentationChartLineIcon,
} from '@heroicons/vue/24/solid'
import DressageIcon from '@/components/icons/DressageIcon.vue'
import Arrow from '@/components/icons/RightIcon.vue'

const router = useRouter()
const currentRouteLayout = computed(() => router.currentRoute.value?.meta?.layout)
const showSecondColumn = ref(true)
const showThirdColumn = ref(true)

watch(
    () => router.currentRoute.value?.meta?.hideSecondColumn,
    (hidden) => {
        showSecondColumn.value = hidden ? false : true
        showThirdColumn.value = hidden ? true : true
    },
    { immediate: true }
)
</script>
