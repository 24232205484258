import { ref, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import axios from '@/lib/API/client'
import fetcher from '@/lib/API/fetcher'
import useSWRV from 'swrv'
import { mutate } from 'swrv'
import { sleep } from '@/lib/helpers/time'
import { getTotalWeight } from '@/lib/helpers/session'

export default () => {
    const route = useRoute()
    const strideBeforeSelected = ref('10')
    const strideAfterSelected = ref('10')
    const sessionId = ref('')
    const page = ref('')
    const horseId = ref('')
    const riderId = ref('')
    const sessionType = ref('')
    const sessionDiscipline = ref('')

    /**
     *
     * use* methods
     *
     **/

    const { data: sessions } = useSWRV(() => {
        //old way to fetch the sessions list
        // return `/user/sessions?page=${page.value}&horse=${horseId.value}&rider=${riderId.value}&type=${sessionType.value}`
        // currently only use in the compare session page
        return `/user/sessions`
    }, fetcher)

    const prefetchSession = async (id) => {
        const urls = [
            `/sessions/${id}`,
            `/sessions/${id}/summary`,
            `/sessions/${id}/statistics`,
            `/sessions/${id}/strides`,
            `/sessions/${id}/strides/statistics`,
            `/sessions/${id}/jumps`,
            `sessions/${id}/jumps/trajectories?before=${strideBeforeSelected.value}&after=${strideAfterSelected.value}`,
        ]
        for (let index = 0; index < urls.length; index++) {
            const url = urls[index]
            await mutate(url, fetcher(url))
            await sleep(100)
            // the second parameter is a Promise
            // SWRV will use the result when it resolves
        }
    }

    const { data: lastSession } = useSWRV(
        () => {
            return '/user/sessions/latest'
        },
        (url) =>
            fetcher(url).then((data) => {
                //we wait 1s in order to leave the others request to be finished
                if (data.id) {
                    setTimeout(() => prefetchSession(data.id), 2000)
                }
                return data
            }),
        { revalidateOnFocus: false, dedupingInterval: 180000 }
    )

    const { data: session, error } = useSWRV(() => (sessionId.value ? `/sessions/${sessionId.value}` : null), fetcher, {
        revalidateOnFocus: false,
        dedupingInterval: 60000,
    })

    const { data: jumpsStatistics } = useSWRV(
        () => (sessionId.value ? `/sessions/${sessionId.value}/summary` : null),
        fetcher,
        {
            revalidateOnFocus: false,
            dedupingInterval: 60000,
        }
    )

    const { data: sessionStatistics } = useSWRV(
        () => (sessionId.value ? `/sessions/${sessionId.value}/statistics` : null),
        fetcher,
        {
            revalidateOnFocus: false,
            dedupingInterval: 60000,
        }
    )

    const { data: strides } = useSWRV(
        () => (sessionId.value ? `/sessions/${sessionId.value}/strides` : null),
        fetcher,
        {
            revalidateOnFocus: false,
            dedupingInterval: 60000,
        }
    )

    const { data: stridesStatistics } = useSWRV(
        () => (sessionId.value ? `/sessions/${sessionId.value}/strides/statistics` : null),
        fetcher,
        { revalidateOnFocus: false, dedupingInterval: 60000 }
    )

    const { data: jumps } = useSWRV(() => (sessionId.value ? `/sessions/${sessionId.value}/jumps` : null), fetcher, {
        revalidateOnFocus: false,
        dedupingInterval: 60000,
    })

    const { data: trajectories } = useSWRV(
        () =>
            sessionId.value
                ? `sessions/${sessionId.value}/jumps/trajectories?before=${strideBeforeSelected.value}&after=${strideAfterSelected.value}`
                : null,
        fetcher,
        { revalidateOnFocus: false, dedupingInterval: 60000 }
    )

    const { data: advices, isValidating: isValidatingAdvices } = useSWRV(
        () => (sessionId.value ? `sessions/${sessionId.value}/advices` : null),
        fetcher,
        {
            revalidateOnFocus: false,
            dedupingInterval: 60000,
        }
    )

    watch(
        [
            () => route.params.sessionId,
            () => route.query.page,
            () => route.query.horse,
            () => route.query.rider,
            () => route.query.type,
            () => route.query.discipline,
            () => route.query.year,
            () => route.query.month,
        ],
        async (
            [newSessionId, newPage, newHorseId, newRiderId, newSessionType, newSessionDiscipline, newYear, newMonth],
            [oldSessionId, oldPage, oldHorseId, oldRiderId, oldSessionType, oldSessionDiscipline, oldYear, oldMonth]
        ) => {
            // console.log(
            //     [newSessionId, newPage, newHorseId, newRiderId, newSessionType],
            //     [oldSessionId, oldPage, oldHorseId, oldRiderId, oldSessionType]
            // )

            page.value = newPage ?? ''
            horseId.value = newHorseId ?? oldHorseId ?? ''
            riderId.value = newRiderId ?? oldRiderId ?? ''
            sessionType.value = newSessionType ?? oldSessionType ?? ''
            sessionDiscipline.value = newSessionDiscipline ?? oldSessionDiscipline ?? ''

            if (oldYear !== newYear) year.value = parseInt(newYear)
            if (oldMonth !== newMonth) month.value = parseInt(newMonth)

            if (newSessionId !== oldSessionId) {
                session.value = undefined
                trajectories.value = undefined
                stridesStatistics.value = undefined
                strides.value = undefined
                jumpsStatistics.value = undefined
                sessionStatistics.value = undefined
                jumps.value = undefined

                //await new Promise((resolve) => setTimeout(resolve, 100)) //wait 100ms in order to have a transition / loader
            }
            sessionId.value = newSessionId ?? oldSessionId ?? '' //will trigger the query of all the others endpoint
        },
        { immediate: true }
    )

    /**
     *
     * mutate* methods
     *
     **/
    const mutateSessions = (page) => {
        mutate(`/user/sessions?page=${page.value}`, fetcher(`/user/sessions?page=${page.value}`))
    }

    const mutateSession = (sessionId, data) => {
        mutate(`/sessions/${sessionId}`, data ? data : fetcher(`/sessions/${sessionId}`))
    }

    /**
     *
     * fetch* methods
     *
     **/

    /**
     *
     * internal methods
     *
     **/
    const changeGaitOfSelection = (gait, timeRange) => {
        for (let index = 0; index < strides.value?.length; index++) {
            const stride = strides.value[index]
            if (stride.pos.time > timeRange[1]) {
                break //stop the loop because we are outside the frame
            }
            if (stride.pos.time > timeRange[0]) {
                stride.gait = gait
                stride.locallyEdited = true
                // console.log('inside', stride.pos.time)
            }
        }
    }
    const updateStride = ({ index, stride, statistics }) => {
        strides.value[index] = stride
        strides.value[index].locallyEdited = true
        stridesStatistics.value[index] = statistics
    }

    /**
     *
     * API methods
     *
     **/
    const updateSession = (_sessionId, data) => {
        return axios.put(`/sessions/${_sessionId}`, data).then((response) => {
            mutateSession(_sessionId, response.data)
            return response.data
        })
    }

    const updateSessionStrides = (_sessionId, strides) => {
        return axios.put(`/sessions/${_sessionId}/strides`, strides).then((response) => {
            return response.data
        })
    }

    const deleteSession = (_sessionId) => {
        return axios.delete(`/sessions/${_sessionId}`).then((response) => {
            mutateSession(_sessionId, {})
            return response.data
        })
    }

    const splitSession = (_sessionId, data) => {
        return axios.post(`/sessions/${_sessionId}/split`, data).then((response) => {
            mutateSession(_sessionId, response.data)
            return response.data
        })
    }

    const reprocessSession = (_sessionId, data) => {
        return axios.post(`/sessions/${_sessionId}/process`, data).then((response) => {
            mutateSession(_sessionId, response.data)
            return response.data
        })
    }

    const shareSession = (_sessionId, email) => {
        return axios.post(`/sessions/${_sessionId}/sharing/invite`, { email }).then((response) => {
            return response.data
        })
    }

    const unshareSession = (_sessionId, userId) => {
        return axios.delete(`/sessions/${_sessionId}/sharing/${userId}`).then((response) => {
            return response.data
        })
    }

    const transferOwnershipSession = (_sessionId, newOwnerId) => {
        return axios.put(`/sessions/${_sessionId}/owners`, { newOwnerId }).then((response) => {
            return response.data
        })
    }

    // const sessionData = {
    //     session: ref(session),
    //     jumps: ref(jumps),
    //     strides: ref(strides),
    //     stridesStatistics: ref(stridesStatistics),
    // }

    const sensorHeight = computed(() => (session.value?.horse?.sensor_height ?? 0) / 100)
    const totalWeight = computed(() => getTotalWeight(session.value?.horse?.weight, [session.value?.rider]))

    const isError = computed(
        () =>
            !!error?.value ||
            (session?.value?.status && !['failed', 'processed', 'validation'].includes(session?.value?.status))
    )
    const isReady = computed(
        () =>
            trajectories?.value &&
            stridesStatistics?.value &&
            session?.value &&
            strides?.value &&
            jumps?.value &&
            !isError.value
    )
    const isLoading = computed(() => !!(!isReady.value && !isError.value))

    return {
        //data
        sessions,
        prefetchSession,
        lastSession,
        session,
        jumpsStatistics,
        sessionStatistics,
        strides,
        stridesStatistics,
        jumps,
        trajectories,
        advices,
        isValidatingAdvices,
        sensorHeight,
        totalWeight,
        isError,
        isReady,
        isLoading,
        //internal methods
        changeGaitOfSelection,
        updateStride,
        //methods
        updateSession,
        updateSessionStrides,
        deleteSession,
        splitSession,
        reprocessSession,
        mutateSession,
        mutateSessions,
        shareSession,
        unshareSession,
        transferOwnershipSession,
        //ref parameter
        strideBeforeSelected,
        strideAfterSelected,
        page,
        sessionId,
        horseId,
        riderId,
        sessionType,
        sessionDiscipline,
    }
}
